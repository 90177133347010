import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export function checkRole() {
	const router = useRouter()
	const store = useStore()
	const { ISLOGIN, USERINFO } = store.state
	if (!ISLOGIN || !USERINFO) {
		router.push({
			path: '/'
		})
		return
	}
	let { authFlag } = USERINFO
	if (authFlag !== 1) {
		router.push({
			path: '/'
		})
	}
}
