/*
 * @Author: huangl
 * @Date: 2023-04-10 11:55:49
 * @LastEditors: Chenminggui111 874512298@qq.com
 * @LastEditTime: 2023-08-15 09:48:04
 * @Description: file content
 * @FilePath: \kingpuluse_pro\src\main.js
 */
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/theme-chalk/index.css";
import Vant from "vant";
import "vant/lib/index.css";

createApp(App).use(store).use(router).use(ElementPlus).use(Vant).mount("#app");
