/*
 * @Author: huangl
 * @Date: 2023-04-17 09:26:44
 * @LastEditors: Chenminggui111 874512298@qq.com
 * @LastEditTime: 2023-08-07 11:31:42
 * @Description: file content
 * @FilePath: \kingpuluse_pro\src\api\home.js
 */
import request from '@/utils/require'

// 产品分类
export function fetchGetProClassify() {
	return request({
		url: '/category/website/list'
	})
}

// 产品列表首页
export function fetchGetProHome() {
	return request({
		url: '/category/categoryAll'
	})
}

// 获取手机验证码
export function fetchGetPhoneSms(mobile) {
	return request({
		url: '/manager​/common​/sms​/' + `${mobile}`,
		method: 'GET'
	})
}

// 手机号码登录
export function fetchPhoneLogin(params) {
	return request({
		url: '/user/smsLogin',
		method: 'POST',
		params
	})
}

// 完善企业信息
export function saveCorporateInfo(data) {
	return request({
		url: '/user/improveCorporateInfo',
		method: 'POST',
		data
	})
}

export function downloadPdf(params) {
	return request({
		url: '/product/downloadPdf',
		method: 'get',
		params
	})
}

// 根据手机号码获取用户信息
export function fetchGetUserInfo(params) {
	return request({
		url: '/user',
		method: 'GET',
		params
	})
}

export function getBannerImg() {
	return request({
		url: '/index/getIndexImage',
		method: 'get'
	})
}

export function allProductList() {
	return request({
		url: '/category/getAllCategories',
		method: 'get'
	})
}
