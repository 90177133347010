<template>
	<div class="container">
		<div class="category-wrap">
			<el-select
				v-model="selectList"
				placeholder="产品分类"
				:style="{ width: store.state.MOBILE ? '100%' : '240px' }"
				multiple
				clearable
				collapse-tags
				popper-class="custom-header"
				:max-collapse-tags="1"
				@change="selectChange(1)"
			>
				<el-option
					v-for="item in proList"
					:key="item.id"
					:label="item.name"
					:value="item.id"
				/>
			</el-select>
		</div>
		<el-scrollbar height="626px">
			<div
				class="pro-wrap"
				v-for="(item, index) in proList"
				:key="index"
			>
				<div class="top">
					<h6 class="title">{{ item.name }}({{ item.value.length }}/{{ item.products.length }})</h6>

					<div class="select-wrap">
						<el-select
							v-model="item.value"
							@change="selectChange(0)"
							placeholder="管理"
							size="small"
							style="width: 80px"
							multiple
							clearable
							collapse-tags
							popper-class="custom-header"
							placement="bottom-start"
							:max-collapse-tags="0"
						>
							<el-option
								v-for="item in item.products"
								:key="item.id"
								:label="item.name"
								:value="item.id"
							>
								<!-- <div class="select-item">
									<span>{{ item.name }}</span>
									<el-checkbox
										v-model="checked1"
										size="large"
									/>
								</div> -->
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="pro-list">
					<div
						class="pro-item"
						v-for="(data, k) in item.products"
						:key="k"
						:class="{ 'mobile-item': store.state.MOBILE }"
					>
						<el-image
							lazy
							:src="data.image"
						/>
						<p class="name">{{ data.name }}</p>
					</div>
				</div>
			</div>
		</el-scrollbar>
		<el-dialog
			v-model="dialogTableVisible"
			width="1060"
			align-center
			:append-to-body="false"
			:show-close="false"
		>
			<div class="content-wrap">
				<div class="product-wrap">
					<div
						class="product-item"
						v-for="(item, index) in productList[pageIndex - 1]"
						:key="index"
					>
						<div class="cover">
							<el-image
								style="width: 160px; height: 160px"
								:src="item.image"
							/>

							<p class="product-name">{{ item.name }}</p>
						</div>
						<div class="content">
							<h6 class="title">{{ item.title }}</h6>
							<div
								class="des"
								v-html="item.specifications"
							></div>
							<div
								class="app"
								v-html="item.scenarios"
							></div>
							<div class="dot"></div>
						</div>
					</div>
					<div class="tip">
						<p class="name">{{ currentItem.categoryName }}</p>
						<p class="index">0{{ pageIndex }}</p>
					</div>
				</div>
				<div class="pagination">
					<el-pagination
						v-model:current-page="pageIndex"
						background
						layout="prev, pager, next"
						:default-page-size="6"
						:total="productList.length * 6"
					/>
				</div>
			</div>
		</el-dialog>
		<div
			class="btn-wrap1"
			v-if="store.state.MOBILE"
		>
			<el-button
				round
				color="#000000"
				:loading="exportLoading"
				:disabled="store.state.productIds.length === 0"
				@click="handleExportPdf"
			>
				导出
			</el-button>
		</div>
		<div
			class="btn-wrap"
			v-else
		>
			<el-button
				round
				:disabled="store.state.productIds.length <= 0"
				@click="dialogTableVisible = true"
			>
				预览
			</el-button>
			<el-button
				type="info"
				round
				:loading="exportLoading"
				:disabled="store.state.productIds.length === 0"
				@click="handleExportPdf"
			>
				导出
			</el-button>
		</div>
	</div>
</template>
<script>
	import { onBeforeUnmount, onMounted, ref, computed } from 'vue'

	import { allProductList, downloadPdf } from '@/api/home'

	import { useRouter } from 'vue-router'
	import { useStore } from 'vuex'
	import { checkRole } from '@/hooks/index'
	import { showLoadingToast, closeToast } from 'vant'

	export default {
		setup() {
			const store = useStore()

			const router = useRouter()

			const pageIndex = ref(1)
			const exportLoading = ref(false)

			const selectList = ref([])
			let dialogTableVisible = ref(false)
			// 产品列表
			let proList = ref()
			const productList = computed(() => {
				let res = []
				proList.value.forEach(element => {
					let { value, products } = element
					products.forEach(data => {
						if (value.includes(data.id)) {
							res.push(data)
						}
					})
				})

				return organizeById(res)
			})
			const currentItem = computed(() => {
				let index = pageIndex.value - 1
				let item = productList.value[index][0]
				return item
			})

			onMounted(() => {
				checkRole()
				getProductList()
			})

			const organizeById = objArray => {
				const idMap = {}
				let results = []
				// Step 1: Group objects by id
				objArray.forEach(obj => {
					const { categoryId } = obj
					if (!idMap[categoryId]) {
						idMap[categoryId] = []
					}
					idMap[categoryId].push(obj)
				})

				// Step 2: Slice arrays to have a maximum of 6 elements
				Object.keys(idMap).forEach(key => {
					const arr = idMap[key]
					for (var i = 0; i < arr.length; i += 6) {
						results.push(arr.slice(i, i + 6))
					}
				})

				return results
			}
			const getProductList = async () => {
				const results = await allProductList()
				let data = results.map(element => {
					let ids = []
					let { id, products } = element
					element.products = products.map(data => {
						if (store.state.productIds.includes(data.id)) {
							ids.push(data.id)
						}
						return data
					})
					element.value = ids
					if (ids.length > 1 && ids.length === element.value.length) {
						selectList.value.push(id)
					}
					return element
				})
				proList.value = data
			}

			// 内容模块
			let modelList = [
				{
					id: 'overview',
					name: '概述'
				},
				{
					id: 'specifications',
					name: '规格'
				},
				{
					id: 'instructions',
					name: '说明'
				},
				{
					id: 'scenarios',
					name: '应用'
				}
			]
			onBeforeUnmount(() => {
				window.removeEventListener('scroll', () => {})
			})

			// 选择产品
			const handleTargetPro = item => {
				currentItem.value = item
			}

			// 选择产品
			const handleExportPdf = async () => {
				exportLoading.value = true
				let ids = store.state.productIds.join(',')
				showLoadingToast({
					message: '导出中...',
					forbidClick: true,
					duration: 0
				})
				let data = await downloadPdf({ ids })
				closeToast()
				let a = document.createElement('a')
				a.style = 'display: none' //
				a.href = data
				a.download = '产品彩页.pdf'
				document.body.appendChild(a)
				a.click()
				document.body.removeChild(a)
				exportLoading.value = false
			}
			// 选择产品
			const selectChange = type => {
				if (type) {
					proList.value.forEach(element => {
						let ids = []
						let { id, products } = element
						if (selectList.value.includes(id)) {
							products.forEach(data => {
								ids.push(data.id)
							})
						}
						element.value = ids
					})
				}
				let res = []
				proList.value.forEach(element => {
					let { value } = element
					res = res.concat(value)
				})

				store.commit('changeIds', res)
			}

			const exportPdf = () => {
				router.push({
					path: '/exportPdf'
				})
			}

			return {
				exportPdf,
				proList,
				pageIndex,
				modelList,
				handleTargetPro,
				dialogTableVisible,
				currentItem,
				scrollTo,
				store,
				productList,
				selectList,
				selectChange,
				exportLoading,
				handleExportPdf
			}
		}
	}
</script>

<style lang="scss" scoped>
	@media screen and (min-width: 1311px) {
		.container {
			width: 1200px;
		}
	}

	::v-deep .el-input__wrapper {
		background-color: #f7f7f7;
	}
	.container {
		margin: 0 auto;
		padding: 12px;

		.top {
			display: flex;
			align-items: center;
			justify-content: space-between;
			.title {
				font-size: 18px;
				color: rgba(0, 0, 0, 1);
				font-weight: 700;
				margin: 16px 0;
				text-align: start;
			}
		}
		.pro-list {
			display: flex;
			flex-wrap: wrap;
			.pro-item {
				padding: 20px 16px;
				box-sizing: border-box;
				background-color: rgba(247, 247, 247, 1);
				margin-right: 12px;
				margin-bottom: 12px;
				display: flex;
				flex-direction: column;
				align-items: center;
				.name {
					width: 120px;
					overflow: hidden;
					margin: 0;
					margin-top: 20px;
					text-align: center;
					font-size: 14px;
					color: rgba(0, 0, 0, 1);
				}
				.el-image {
					width: 84px;
					height: 84px;
				}
			}
			.mobile-item {
				padding: 6px 10px;
				margin-right: 5px;
				margin-bottom: 5px;
				.el-image {
					width: 44px;
					height: 44px;
				}
				.name {
					margin-top: 4px;
					width: 48px;
					font-size: 12px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}
			}
		}
		.btn-wrap {
			display: flex;
			justify-content: center;
			margin-top: 12px;
			.el-button {
				width: 200px;
			}
		}
		.btn-wrap1 {
			position: fixed;
			bottom: 12px;
			left: 0;
			right: 0;
			display: flex;
			justify-content: center;
			.el-button {
				width: 96%;
			}
		}
		::v-deep .el-dialog {
			background: transparent;
			.des {
				width: 260px;
				height: 148px;
				max-height: 148px;
				overflow: hidden;

				h3,
				p,
				strong {
					margin: 0;
					font-size: 12px;
					color: #999999;
					margin: 0;
					margin: 0;
					padding: 0;
					font-weight: 500 !important;
				}
			}
			.app {
				width: 260px;
				h3,
				p,
				li,
				strong {
					font-size: 12px;
					color: #999999;
					display: flex;
					align-items: center;
					margin: 0;
					padding: 0;
					font-weight: 500 !important;
				}
			}
			.el-input__wrapper {
				background-color: red;
			}
		}

		.product-wrap {
			position: relative;
			width: 921px;
			height: 690px;
			padding: 22px;
			box-sizing: border-box;
			display: flex;
			align-items: self-start;
			align-content: flex-start;
			flex-wrap: wrap;
			background-color: #fff;
			.product-item {
				display: flex;
				align-items: center;
				width: 425px;
				margin-bottom: 22px;
				.cover {
					display: flex;
					flex-direction: column;
					align-items: center;
				}
				.product-name {
					font-size: 16px;
					margin: 0;
					font-weight: bold;
					text-align: center;
					color: rgba(51, 51, 51, 1);
				}
				.content {
					position: relative;
					margin-left: 28px;
					min-height: 180px;
					.dot {
						position: absolute;
						left: -12px;
						top: 0;
						bottom: 0;
						width: 1px;
						background: rgba(255, 141, 26, 1);
					}
					.dot::after {
						content: '';
						position: absolute;
						left: -4px;
						top: 0;
						width: 8px;
						height: 8px;
						border-radius: 50%;
						background: rgba(255, 141, 26, 1);
					}
					.dot::before {
						content: '';
						position: absolute;
						left: -4px;
						bottom: 0;
						width: 8px;
						height: 8px;
						border-radius: 50%;
						background: rgba(255, 141, 26, 1);
					}
				}
				.title {
					font-size: 14px;
					color: rgba(51, 51, 51, 1);
					margin: 0;
				}
			}
			.tip {
				position: absolute;
				bottom: 0;
				right: 34px;
				color: rgba(51, 51, 51, 1);
				display: flex;
				align-items: center;
				font-weight: 700;
				.name {
					font-size: 16px;

					margin-right: 22px;
				}
				.index {
					font-size: 14px;
					display: flex;
					align-items: center;
				}
				.index::before {
					content: '';
					// position: absolute;
					// left: 1px;
					// bottom: 1px;
					display: block;
					margin-right: 4px;
					width: 2px;
					height: 10px;
					background: rgba(51, 51, 51, 1);
				}
				.index::after {
					content: '';
					// position: absolute;
					// left: 1px;
					// bottom: 1px;
					display: block;
					width: 20px;
					height: 10px;
					margin-left: 4px;
					background: rgba(51, 51, 51, 1);
				}
			}
		}
		.content-wrap {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		.pagination {
			margin-top: 22px;
		}
	}
</style>
