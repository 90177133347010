/*
 * @Author: huangl
 * @Date: 2023-04-10 11:55:49
 * @LastEditors: Chenminggui111 874512298@qq.com
 * @LastEditTime: 2023-08-08 14:56:48
 * @Description: file content
 * @FilePath: \kingpuluse_pro\src\router\index.js
 */
import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ProDetail from '../views/ProDeatil/index.vue'
import ProClassify from '../views/proClassify/index.vue'
import ProList from '../views/mobileProList/index.vue'
import ProTools from '../views/tools/index.vue'
import Export from '../views/export/index.vue'
const routes = [
	{
		path: '/',
		name: 'home',
		component: HomeView
	},
	{
		path: '/pro',
		name: 'ProDetail',
		component: ProDetail
	},
	{
		path: '/classify',
		name: 'ProClassify',
		component: ProClassify
	},
	{
		path: '/proList',
		name: 'ProductionList',
		component: ProList
	},
	{
		path: '/tools',
		name: 'ProTools',
		component: ProTools
	},
	{
		path: '/export',
		name: 'Export',
		component: Export
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes,
	scrollBehavior() {
		return { top: 0 }
	}
})

export default router
