<!--
 * @Author: Chenminggui111 874512298@qq.com
 * @Date: 2023-08-08 14:27:33
 * @LastEditors: Chenminggui111 874512298@qq.com
 * @LastEditTime: 2023-08-09 10:33:35
 * @FilePath: \kingpuluse_pro\src\views\tools\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
	<div class="container-app">
		<data v-if="!store.state.MOBILE">
			<div class="downLoad">下载列表</div>
			<div class="tools-list">
				<el-table
					:data="toolsList"
					:border="true"
				>
					<el-table-column
						label="项目名称"
						prop="name"
						aligh="center"
					></el-table-column>
					<el-table-column
						label="二维码"
						aligh="center"
					>
						<template v-slot="scope">
							<img
								:src="scope.row.uploadUrl"
								style="width: 100px; height: 100px"
							/>
						</template>
					</el-table-column>
					<el-table-column
						label="下载链接"
						aligh="center"
					>
						<template v-slot="scope">
							<a :href="scope.row.url">下载</a>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</data>
		<div
			class="tool-wrap"
			v-else
		>
			<div
				class="tool-list"
				v-for="(item, index) in toolsList"
				:key="index"
			>
				<h4>{{ item.name }}</h4>
				<div class="tool-item">
					<el-image
						style="width: 100px; height: 100px"
						:src="item.uploadUrl"
					/>
					<div>
						<div style="margin-bottom: 12px">
							<el-text
								class="mx-1"
								type="info"
							>
								连接
							</el-text>
						</div>
						<el-link
							:href="item.url"
							type="primary"
							target="_blank"
						>
							点击下载
						</el-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import { onMounted, ref } from 'vue'
	import { getToolsList } from '@/api/tools'

	import { useStore } from 'vuex'
	export default {
		setup() {
			const store = useStore()

			onMounted(() => {
				fhGetToolsList()
			})
			const toolsList = ref(null)
			const fhGetToolsList = async () => {
				const results = await getToolsList()
				toolsList.value = results
			}
			return {
				store,
				toolsList
			}
		}
	}
</script>
<style lang="scss" scoped>
	.container-app {
		padding: 40px 0;

		margin: 0 auto;
		overflow: hidden;
	}
	@media screen and (min-width: 1311px) {
		.container-app {
			width: 1200px;
		}
	}
	.downLoad {
		font-size: 28px;
		font-weight: bold;
	}
	.tools-list {
		padding: 20px;
	}
	.list-item {
		border: 1px solid #ccc;
		padding: 20px;
		display: flex;
		align-items: center;
		color: #000;
	}
	.tool_title {
		font-size: 16px;
		font-weight: bold;
	}
	.tool-wrap {
		padding: 0 22px;
		.tool-list {
			.tool-item {
				font-size: 14px;
				display: flex;
				align-items: center;
				border-bottom: 1px solid rgba(229, 229, 229, 1);
			}
		}
	}
</style>
