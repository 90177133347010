<!--
 * @Author: huangl
 * @Date: 2023-04-24 09:38:48
 * @LastEditors: Chenminggui111 874512298@qq.com
 * @LastEditTime: 2023-08-09 14:49:31
 * @Description: file content
 * @FilePath: \kingpuluse_pro\src\views\mobileProList\index.vue
-->
<template>
	<div class="container">
		<div class="pro_title">{{ proName }}</div>
		<div class="pro_list">
			<div
				class="item"
				v-for="(item, index) in proList.data"
				:key="index"
				@click="handleClickItem(item.id)"
			>
				<div class="item_img">
					<img
						:src="item.image"
						class="img"
					/>
				</div>
				<div class="content">
					<div class="item_name">{{ item.name }}</div>
					<div class="item_info">
						{{ item.profile }}
					</div>
				</div>
			</div>
			<div
				class="item"
				v-if="proList.data.length % 2 !== 0"
			></div>
		</div>
	</div>
</template>
<script>
	import { onBeforeMount, reactive, ref, onMounted } from 'vue'
	import { fetchGetProducts } from '@/api/proList'
	import { useRoute, useRouter } from 'vue-router'
	import { checkRole } from '@/hooks/index'

	export default {
		setup() {
			const $route = useRoute()
			const router = useRouter()
			let proName = ref('')
			const { id, name } = $route.query
			proName.value = name
			const proList = reactive({
				data: []
			})
			onMounted(() => {
				checkRole()
			})
			onBeforeMount(async () => {
				try {
					proList.data = await fetchGetProducts(id)
				} catch (error) {
					console.log(error)
				}
			})
			const handleClickItem = proId => {
				router.push({
					path: '/pro',
					query: { id, proId }
				})
			}
			return {
				proName,
				proList,
				handleClickItem
			}
		}
	}
</script>
<style lang="scss" scoped>
	.container {
		width: 100%;
	}
	img {
		width: 100%;
		height: 100%;
	}
	.pro_title {
		font-size: 14px;
		color: rgba(56, 56, 56, 1);
		text-align: center;
		padding: 14px 0;
		font-weight: bold;
	}
	.pro_list {
		padding: 10px;
		display: flex;
		flex-wrap: wrap;
		box-sizing: border-box;
		justify-content: space-between;
		align-items: center;
	}
	.item {
		width: 48.5%;
		border-radius: 10px;
		overflow: hidden;
		box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
		margin-bottom: 10px;
	}
	.item_img {
		width: 100%;
		height: 149px;
	}
	.content {
		padding: 14px;
		.item_name {
			font-weight: bold;
			color: rgba(0, 0, 0, 1);
			text-align: center;
			margin-bottom: 12px;
		}
		.item_info {
			font-size: 11px;
			color: rgba(153, 153, 153, 1);
			text-overflow: -o-ellipsis-lastline;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			line-clamp: 2;
			-webkit-box-orient: vertical;
		}
	}
</style>
