<template>
	<div class="container">
		<div v-if="store.state.MOBILE">
			<div
				class="back"
				@click="back"
			>
				<el-icon color="#fff"><ArrowLeftBold /></el-icon>
			</div>
			<div class="product-cover">
				<el-image
					style="width: 391px; height: 391px"
					:src="currentItem.image"
				></el-image>
			</div>

			<van-floating-panel
				v-model:height="height"
				:anchors="anchors"
			>
				<div class="tab-wrap">
					<div class="proName">{{ currentItem.name }}</div>
					<van-tabs
						v-model:active="currentTab"
						:swipeable="true"
						:before-change="handleClickTab"
					>
						<van-tab
							v-for="(item, index) in modelList"
							:key="index"
							:title="item.name"
							title-active-color="#FF8D1A"
							title-inactive-color="#FF8D1A"
						></van-tab>
					</van-tabs>
					<div
						class="content"
						v-html="content"
					></div>
				</div>
			</van-floating-panel>
		</div>
		<div v-else>
			<el-scrollbar>
				<div class="box">
					<div
						class="box_item"
						v-for="(item, index) in compProduct"
						:key="index"
						@click="handleTargetPro(item)"
					>
						<img
							:src="item.image"
							class="items_img"
						/>

						<div :class="currentItem.id === item.id ? 'select_items' : ''">{{ item.name }}</div>
						<div
							class="checkbox"
							v-if="store.state.USERINFO.authFlag"
						>
							<el-checkbox
								@change="addExport(item)"
								v-model="item.check"
								size="large"
							/>
						</div>
					</div>
				</div>
			</el-scrollbar>
			<!-- tabbar -->
			<div class="product-warp">
				<div class="cover-box">
					<el-image
						style="width: 391px; height: 391px"
						:src="currentItem.image"
					>
						<template #error>
							<div class="image-slot">
								<el-icon></el-icon>
							</div>
						</template>
					</el-image>

					<h4 class="name">{{ currentItem.name }}</h4>
					<el-button
						v-if="store.state.USERINFO.authFlag"
						round
						plain
						color="#000000"
						@click="addExport(currentItem)"
					>
						{{ store.state.productIds.includes(currentItem.id) ? '取消添加' : '添加至导出列表' }}
					</el-button>
				</div>
				<div class="content-wrap">
					<div class="tabbar">
						<div
							v-for="(item, index) in modelList"
							:key="index"
							:class="{ active: currentTab === index }"
							class="class-item"
							@click="handleChangeTab(index)"
						>
							{{ item.name }}
						</div>
					</div>
					<div
						id="overview"
						v-html="content"
					></div>
				</div>
			</div>
		</div>
		<div
			class="footer"
			v-if="store.state.USERINFO.authFlag && store.state.MOBILE"
		>
			<el-button
				round
				color="#000000"
				@click="addExport(currentItem)"
			>
				{{ store.state.productIds.includes(currentItem.id) ? '取消添加' : '添加至导出列表' }}
			</el-button>
			<el-button
				round
				color="#000000"
				@click="exportPdf"
			>
				去下载
			</el-button>
		</div>
		<div
			v-if="!store.state.MOBILE && store.state.USERINFO.authFlag"
			class="btn"
			@click="exportPdf"
		>
			去导出
		</div>
	</div>
</template>
<script>
	import { onBeforeUnmount, onMounted, reactive, ref, isRef, computed, getCurrentInstance, toRaw, watch } from 'vue'
	import { fetchGetProducts } from '@/api/proList'
	import { useRoute, useRouter } from 'vue-router'
	import { useStore } from 'vuex'
	import { ElMessage } from 'element-plus'
	import { ArrowLeftBold } from '@element-plus/icons-vue'
	import { checkRole } from '@/hooks/index'

	export default {
		components: {
			ArrowLeftBold
		},
		setup() {
			let { proxy } = getCurrentInstance()
			const store = useStore()
			const $route = useRoute()
			const router = useRouter()
			const anchors = [Math.round(0.5 * window.innerHeight), Math.round(0.8 * window.innerHeight)]
			const height = ref(anchors[0])
			const currentTab = ref(0)
			// 产品改成当前产品的id
			const currentItem = ref({})
			// 产品列表
			let proList = reactive({ data: [] })
			const content = computed(() => {
				let { overview, specifications, instructions, scenarios } = currentItem.value
				let arr = [overview, specifications, instructions, scenarios]
				return arr[currentTab.value]
			})
			const compProduct = computed(() => {
				let res = proList.data.map(item => {
					item.check = store.state.productIds.includes(item.id)
					return item
				})
				return res
			})
			onMounted(() => {
				checkRole()
				getProductList()
			})
			watch(
				() => $route.query,
				() => {
					getProductList()
				}
			)

			const getProductList = async () => {
				const { id, proId } = $route.query
				if (id) {
					const results = await fetchGetProducts(id)
					proList.data = results
					if (results.length) {
						currentItem.value = results[0]
					} else {
						currentItem.value = {}
					}
				}
				if (proId) {
					const target = proList.data.find(item => {
						return item.id === proId
					})
					currentItem.value = target
				}
			}
			// 内容模块
			let modelList = [
				{
					id: 'overview',
					name: '概述'
				},
				{
					id: 'specifications',
					name: '规格'
				},
				{
					id: 'instructions',
					name: '说明'
				},
				{
					id: 'scenarios',
					name: '应用'
				}
			]
			onBeforeUnmount(() => {
				window.removeEventListener('scroll', () => {})
			})

			// 选择产品
			const handleTargetPro = item => {
				currentItem.value = item
			}
			// 选择产品
			const addExport = item => {
				let id = 0
				if (isRef(item)) {
					id = item.value.id
				} else {
					id = item.id
				}

				let productIds = store.state.productIds

				if (productIds.includes(id)) {
					let findIndex = productIds.findIndex(item => item === id)
					productIds.splice(findIndex, 1)
				} else {
					productIds.push(id)
				}

				store.commit('changeIds', productIds)
			}

			// 选择模块栏
			const handleChangeTab = index => {
				// 登录逻辑
				const isLogin = store.state.ISLOGIN
				const userInfo = toRaw(store.state.USERINFO)
				if (isLogin) {
					if (userInfo.authFlag === 1) {
						currentTab.value = index
					} else {
						ElMessage({
							type: 'warning',
							message: '您的账号暂未通过审核,请联系管理员开通查阅资格'
						})
					}
				} else {
					proxy.$root.openLogin()
					// 打开登录弹窗
				}
			}

			const scrollTo = offsetTop => {
				window.scrollTo({
					top: offsetTop - 50,
					behavior: 'smooth'
				})
			}
			const exportPdf = () => {
				router.push({
					path: '/export'
				})
			}
			const back = () => {
				router.back()
			}

			// 点击tab
			const handleClickTab = index => {
				const isLogin = store.state.ISLOGIN
				const userInfo = toRaw(store.state.USERINFO)
				if (isLogin) {
					if (userInfo.authFlag === 1) {
						currentTab.value = index
					} else {
						ElMessage({
							type: 'warning',
							message: '您的账号暂未通过审核,请联系管理员开通查阅资格'
						})
						currentTab.value = 0
					}
				}
			}

			return {
				back,
				exportPdf,
				content,
				proList,
				height,
				anchors,
				currentTab,
				handleChangeTab,
				modelList,
				handleTargetPro,
				currentItem,
				scrollTo,
				store,
				addExport,
				handleClickTab,
				compProduct
			}
		}
	}
</script>

<style lang="scss" scoped>
	.moble_padding {
		padding: 0 10px;
		#overview {
			img {
				width: 100%;
				height: 100%;
			}
		}
		p {
			display: block;
			img {
				display: flex;
				width: 100%;
				height: 100%;
			}
		}
	}

	.container {
		position: relative;
		overflow: hidden;
		margin: 0 auto;
	}
	@media screen and (min-width: 1311px) {
		.container {
			width: 1200px;
		}
	}
	.back {
		position: absolute;
		left: 14px;
		top: 14px;
		width: 30px;
		height: 30px;
		opacity: 1;
		border-radius: 50%;
		background: rgba(0, 0, 0, 0.3);
		z-index: 22;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.box {
		padding: 30px;
		display: flex;
		box-sizing: border-box;
		.box_item {
			position: relative;
			text-align: center;
			color: rgba(56, 56, 56, 1);
			font-size: 16px;
			margin-right: 77px;
			display: inline-block;
			cursor: pointer;

			.items_img {
				width: 84px;
				height: 84px;
				margin: 0 auto;
				margin-bottom: 10px;
			}

			.select_items {
				color: rgba(42, 130, 228, 1);
			}
			.checkbox {
				position: absolute;
				right: 0;
				top: 0;
				width: 40px;
				height: 40px;
			}

			div {
				font-size: 16px;
				width: 140px;
				text-align: center;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
	}
	.product-warp {
		padding-top: 22px;
		border-top: 1px solid rgba(229, 229, 229, 1);
		display: flex;
		margin-bottom: 74px;
		.cover-box {
			display: flex;
			flex-direction: column;
			align-items: center;
			.name {
				text-align: center;
			}
			.el-button {
				width: 220px;
			}
		}

		.content-wrap {
			margin-left: 44px;
			.tabbar {
				display: flex;
				.class-item {
					cursor: pointer;
					width: 120px;
					height: 48px;
					line-height: 48px;
					text-align: center;
					color: rgba(166, 166, 166, 1);
					background-color: #f7f7f7;
				}

				.active {
					background-color: #000000;
					color: #fff;
				}
			}
		}
	}

	.connect {
		div {
			width: 100%;
			min-height: 80vh;
		}
	}

	.padding {
		width: 90rem;
		margin: 0 auto;
		overflow: hidden;
		min-height: 600px;
	}

	::v-deep .tab-wrap {
		.content {
			h3,
			p,
			span {
				margin: 0;
				font-size: 10px;
				color: rgba(153, 153, 153, 1);
			}
		}
	}
	.tab-wrap {
		background-color: #fff;
		padding-bottom: 12px;
		.proName {
			padding: 14px 0;
			font-size: 16px;
			font-weight: 600;
			text-align: center;
		}

		::v-deep .content {
			border-top: 1px solid rgba(204, 204, 204, 1);
			padding: 22px;
			padding-bottom: 0;
			height: 100%;
			overflow-y: scroll;
			h3,
			p {
				margin: 0;
			}
			img {
				width: 100%;
			}
		}
	}
	::v-deep .van-tabs__nav {
		.van-tab {
			color: #999999;
		}
		.van-tab--active {
			color: #ff8d1a;
		}
		.van-tabs__line {
			background-color: #ff8d1a;
		}
	}
	::v-deep .van-floating-panel {
		border-radius: 40px 40px 0 0;
		box-shadow: 0px -2px 6px rgba(000, 000, 000, 0.1);
	}
	.footer {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 12px;
		display: flex;
		z-index: 999999;
		background-color: #fff;
		justify-content: space-between;
		.el-button {
			width: 143px;
		}
	}

	.btn {
		position: absolute;
		right: 0px;
		bottom: 86px;
		width: 80px;
		height: 80px;
		line-height: 80px;
		text-align: center;
		font-size: 16px;
		color: #fff;
		cursor: pointer;
		background: rgba(0, 0, 0, 1);
		border-radius: 50%;
		box-shadow: inset 0px 0px 4px 2px rgba(255, 255, 255, 0.6), 0px 0px 6px rgba(0, 0, 0, 0.25);
	}
</style>
