/*
 * @Author: huangl
 * @Date: 2023-04-10 11:55:49
 * @LastEditors: huangl
 * @LastEditTime: 2023-04-25 16:01:37
 * @Description: file content
 * @FilePath: \kingpuluse_pro\src\store\index.js
 */
import { createStore } from 'vuex'

export default createStore({
	state: {
		CURRENT_NAV: sessionStorage.getItem('currentNav') ? sessionStorage.getItem('currentNav') : '0',
		MOBILE: false,
		productIds: localStorage.getItem('productIds') ? localStorage.getItem('productIds').split(',') : [],
		ISLOGIN: localStorage.getItem('isLogin') ? localStorage.getItem('isLogin') : false,
		USERINFO: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {}
	},
	getters: {},
	mutations: {
		changeNav(state, nav) {
			state.CURRENT_NAV = nav
			sessionStorage.setItem('currentNav', nav)
		},
		changeIds(state, data) {
			state.productIds = data
			localStorage.setItem('productIds', data)
		},
		chageLoginState(state, flag) {
			state.ISLOGIN = flag
			localStorage.setItem('isLogin', flag)
		},
		changeUserInfo(state, data) {
			state.USERINFO = data
			localStorage.setItem('userInfo', JSON.stringify(data))
		}
	},
	actions: {},
	modules: {}
})
