/*
 * @Author: huangl
 * @Date: 2023-04-11 11:35:15
 * @LastEditors: huangl
 * @LastEditTime: 2023-07-11 11:12:57
 * @Description: file content
 * @FilePath: \kingpuluse_pro\src\utils\require.js
 */
import axios from 'axios'
// import { MessageBox, Message } from "element-ui";
import store from '@/store'
import { showToast } from 'vant'

// create an axios instance
const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
	// withCredentials: true, // send cookies when cross-domain requests
	timeout: 60000 // request timeout
})

// request interceptor
service.interceptors.request.use(
	config => {
		// 过滤转义
		config.url = decodeURI(encodeURI(config.url).replace(/%E2%80%8B/g, ''))
		// TODO do something before request is sent
		if (store.getters.token) {
			// let each request carry token
			// ['X-Token'] is a custom headers key
			// please modify it according to the actual situation
			// config.headers['accessToken'] = getToken()
		}
		// TODO

		return config
	},
	error => {
		// do something with request error
		return Promise.reject(error)
	}
)

// response interceptor
service.interceptors.response.use(
	/**
	 * If you want to get http information such as headers or status
	 * Please return  response => response
	 */

	/**
	 * Determine the request status by custom code
	 * Here is just an example
	 * You can also judge the status by HTTP Status Code
	 */
	response => {
		const res = response.data
		// if the custom code is not 20000, it is judged as an error.
		if (res.code !== 200) {
			console.log(res)
			// Message({
			//   message: res.message || "Error",
			//   type: "error",
			//   duration: 5 * 1000,
			// });
			// 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
			if (res.code === 403 || res.code === 50012 || res.code === 50014) {
				// to re-login
				// MessageBox.confirm("登录状态失效, 请重新登录", "登录状态失效", {
				//   confirmButtonText: "去登录",
				//   cancelButtonText: "取消",
				//   type: "warning",
				// }).then(() => {
				//   store.dispatch("user/resetToken").then(() => {
				//     location.reload();
				//   });
				// });
			}
			showToast(res.message || '服务异常')
			return Promise.reject(new Error(res.message || 'Error'))
		} else {
			return res.data
		}
	},
	error => {
		console.log('err' + error) // for debug
		// Message({
		//   message: error.message,
		//   type: "error",
		//   duration: 5 * 1000,
		// });

		window.location.reload()
		return Promise.reject(error)
	}
)

export default service
