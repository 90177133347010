<!--
 * @Author: huangl
 * @Date: 2023-04-22 11:29:15
 * @LastEditors: huangl
 * @LastEditTime: 2023-05-10 14:17:55
 * @Description: file content
 * @FilePath: \kingpuluse_pro\src\views\proClassify\index.vue
-->
<template>
	<div class="container">
		<div
			class="list_item"
			v-for="(item, index) in navList.data"
			:key="index"
			@click="handleClickItem(item)"
		>
			<div>{{ item.name }}</div>
			<div class="icon">></div>
		</div>
	</div>
</template>
<script>
	import { onBeforeMount, reactive } from 'vue'
	import { fetchGetProHome } from '@/api/home'
	import { useRouter } from 'vue-router'
	export default {
		setup() {
			const router = useRouter()
			let navList = reactive({
				data: [
					{
						id: '0',
						name: '首页',
						path: '/'
					}
				]
			})
			onBeforeMount(async () => {
				const results = await fetchGetProHome()
				let pathArr = [
					{
						id: '888',
						name: '工具下载',
						path: '/tools'
					},
					{
						id: '777',
						path: '/export',
						name: '产品彩页下载'
					}
				]
				navList.data.push(...results, ...pathArr)
			})

			const handleClickItem = item => {
				let { id, name, path } = item
				if (path) {
					router.push({
						path
					})
				} else {
					router.push({
						path: '/proList',
						query: { id, name }
					})
				}
			}
			return {
				navList,
				handleClickItem
			}
		}
	}
</script>
<style scoped>
	.container {
		padding: 0 23px;
		background: rgba(255, 255, 255, 1);
		font-size: 14px;
		min-height: calc(100vh - 257px);
	}
	.list_item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 50px;
		border-bottom: 1px solid rgba(239, 239, 239, 1);
		color: #000;
	}
	.icon {
		width: 6px;
		height: 10px;
		color: rgba(196, 196, 196, 1);
	}
</style>
