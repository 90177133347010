<!--
 * @Author: huangl
 * @Date: 2023-04-10 16:33:38
 * @LastEditors: Chenminggui111 874512298@qq.com
 * @LastEditTime: 2023-08-14 16:47:07
 * @Description: file content
 * @FilePath: \kingpuluse_pro\src\components\proList\index.vue
-->
<template>
	<div class="product-wrap">
		<div
			v-for="(item, index) in products"
			:key="index"
			class="box"
			:class="index % 2 ? 'bg-white' : 'bg-gray'"
		>
			<div
				class="title"
				:style="{ 'font-size': store.state.MOBILE ? '20px' : '24px' }"
				@click="handleToCateDetail(item)"
				v-if="item.products.length > 0"
			>
				{{ item.name }}
			</div>
			<div v-if="item.products.length > 0">
				<div
					v-if="item.products.length > 1"
					class="proListBox"
				>
					<div
						class="pro-item"
						:class="{ 'mobile-item': store.state.MOBILE, 'bg-gray': index % 2 }"
						v-for="(child, key) in item.products"
						:key="key"
					>
						<div class="pro_info">{{ child.name }}</div>
						<div class="pro_title">{{ child.title }}</div>
						<div
							class="moreBtn"
							@click="handleClickProDetail(item.id, child.id)"
						>
							了解更多
						</div>
						<div class="pro_img">
							<el-image
								:src="child.image"
								class="pro_img"
								fit="contain"
							/>
						</div>
					</div>
				</div>
				<div
					v-else
					class="product-item1"
					:class="{ 'product-item2': !store.state.MOBILE }"
				>
					<div class="pro_img">
						<el-image
							:src="item.products[0].image"
							class="pro_img"
							fit="contain"
						/>
					</div>
					<view>
						<div class="pro_info">{{ item.products[0].name }}</div>
						<div class="pro_title">{{ item.products[0].title }}</div>
						<div
							class="moreBtn"
							@click="handleClickProDetail(item.id, item.products[0].id)"
						>
							了解更多
						</div>
					</view>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import { useRouter } from 'vue-router'
	import { useStore } from 'vuex'
	export default {
		props: {
			products: {
				type: Array
			}
		},
		setup() {
			const router = useRouter()
			const store = useStore()
			const handleClickProDetail = (id, proId) => {
				router.push({
					path: '/pro',
					query: { id, proId }
				})
			}

			const handleToCateDetail = row => {
				const { products } = row
				const proId = products[0].id
				const id = row.id
				router.push({
					path: '/pro',
					query: { id, proId }
				})
			}

			return {
				handleClickProDetail,
				store,
				handleToCateDetail
			}
		}
	}
</script>
<style lang="scss" scoped>
	/* .box {
  margin-top: 40px;
} */
	.container {
		width: 100%;
	}
	.title {
		padding: 20px;
		font-weight: 700;
		font-weight: bold;
		text-align: center;
		color: rgba(56, 56, 56, 1);
		cursor: pointer;
	}
	.box {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-bottom: 22px;
		// background: #f7f7f7;
	}

	@media screen and (min-width: 1200px) {
		.proListBox {
			width: 1170px;
		}
	}

	.proListBox {
		display: flex;
		flex-wrap: wrap;
		padding: 0 2%;
		justify-content: space-around;
	}

	.pro-item {
		width: 278px;
		height: 320px;
		margin-bottom: 30px;
		background: #fff;
		padding: 20px 0;
		font-size: 16px;
		text-align: center;
		box-sizing: border-box;
		.pro_title {
			color: rgba(128, 128, 128, 1);
			margin-bottom: 9px;
		}

		.pro_info {
			font-weight: bold;

			color: rgba(56, 56, 56, 1);
		}

		.moreBtn {
			width: 105px;
			line-height: 30px;
			text-align: center;
			background: rgba(0, 0, 0, 1);
			border-radius: 50px;
			color: rgba(255, 255, 255, 1);
			font-size: 14px;
			font-weight: 400;
			margin: 20px auto;
			cursor: pointer;
		}
		.pro_img {
			margin: 0 auto;
			width: 131px;
			height: 131px;
		}
	}
	.mobile-item {
		padding: 14px;
		box-sizing: border-box;
		width: 48%;
		height: auto;
		font-size: 13px;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 8px;
		.pro_img {
			width: 120px;
			height: 100px;
			margin: 0;
		}
		.pro_title {
			color: rgba(128, 128, 128, 1);
			margin: 6px 0;
		}

		.pro_info {
			font-weight: bold;
			color: rgba(56, 56, 56, 1);
		}

		.moreBtn {
			width: 80px;
			line-height: 24px;
			text-align: center;
			background: rgba(0, 0, 0, 1);
			border-radius: 50px;
			color: rgba(255, 255, 255, 1);
			font-size: 12px;
			font-weight: 400;
			margin: 0 auto;
			margin-bottom: 10px;
			cursor: pointer;
		}
	}
	.product-item1 {
		width: 92vw;
		height: 140px;
		font-size: 14px;
		display: flex;
		align-items: center;
		padding: 6px 12px;
		box-sizing: border-box;
		background-color: rgba(247, 247, 247, 1);
		justify-content: space-between;
		.pro_img {
			width: 110px;
			height: 110px;
		}
		.pro_title {
			color: rgba(128, 128, 128, 1);
			margin-bottom: 9px;
		}

		.pro_info {
			font-weight: bold;
			color: rgba(56, 56, 56, 1);
		}

		.moreBtn {
			width: 80px;
			line-height: 24px;
			text-align: center;
			background: rgba(0, 0, 0, 1);
			border-radius: 50px;
			color: rgba(255, 255, 255, 1);
			font-size: 14px;
			font-weight: 400;
			margin: 0 auto;
			cursor: pointer;
		}
	}
	.product-item2 {
		width: 92vw;
		height: 440px;
		font-size: 14px;
		display: flex;
		align-items: center;
		padding: 87px 188px;
		box-sizing: border-box;
		background-color: rgba(247, 247, 247, 1);
		justify-content: space-between;
		.pro_img {
			width: 276px;
			height: 276px;
		}
		.pro_title {
			color: rgba(128, 128, 128, 1);
			margin: 9px 0;
		}

		.pro_info {
			font-weight: bold;
			color: rgba(56, 56, 56, 1);
		}

		.moreBtn {
			width: 80px;
			line-height: 24px;
			text-align: center;
			background: rgba(0, 0, 0, 1);
			border-radius: 50px;
			color: rgba(255, 255, 255, 1);
			font-size: 14px;
			font-weight: 400;
			margin: 0 auto;
			cursor: pointer;
		}
	}

	.bg-gray {
		background-color: #f7f7f7;
	}
</style>
