<!--
 * @Author: huangl
 * @Date: 2023-04-22 17:32:24
 * @LastEditors: huangl
 * @LastEditTime: 2023-07-07 10:47:18
 * @Description: file content

 * @FilePath: \kingpuluse_pro\src\components\login\index.vue
-->
<template>
	<div>
		<el-dialog
			v-model="dialogVisible"
			title="登录"
			width="25%"
			:fullscreen="store.state.MOBILE"
			:close-on-click-modal="false"
		>
			<div class="login_class">
				<el-form
					:model="form"
					label-width="80"
					label-position="right"
				>
					<el-form-item label="手机号码">
						<el-input
							type="tel"
							v-model="form.phone"
						/>
					</el-form-item>
					<el-form-item label="验证码">
						<el-input
							v-model="form.vaild"
							type="tel"
						>
							<template #append>
								<span v-if="vaildFlag">{{ num }}s</span>
								<el-button
									v-else
									@click="handleVaildBtn"
								>
									发送验证码
								</el-button>
							</template>
						</el-input>
					</el-form-item>
				</el-form>
				<div style="margin-top: 50px">
					<el-button
						v-if="form.phone.length > 0 && form.vaild.length > 0"
						type="primary"
						class="login_btn"
						round
						@click="handleClickLogin"
					>
						登录
					</el-button>
					<el-button
						v-else
						type="info"
						size="large"
						class="login_btn"
						round
					>
						登录
					</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import { reactive, ref } from 'vue'
	import { useStore } from 'vuex'
	import { fetchGetPhoneSms, fetchPhoneLogin } from '@/api/home'
	// import { ElMessage } from "element-plus";
	export default {
		setup() {
			const store = useStore()
			const dialogVisible = ref(false)
			const form = reactive({
				phone: '',
				vaild: ''
			})
			const num = ref(60)
			const vaildFlag = ref(false)
			const handleVaildBtn = async () => {
				vaildFlag.value = true
				await fetchGetPhoneSms(form.phone)
				interverNum()

				// var regExp = new RegExp("^1[3578]\\d{9}$");
				// if (regExp.test(form.phone)) {

				// } else {
				//   ElMessage({
				//     type: "warning",
				//     message: "请输入正确的手机号码",
				//   });
				// }
			}
			// 倒计时组件
			const interverNum = () => {
				const numInt = setInterval(() => {
					if (num.value > 0) {
						num.value--
					} else {
						vaildFlag.value = false
						num.value = 60
						clearInterval(numInt)
					}
				}, 1000)
			}

			const handleClickLogin = async () => {
				const params = {
					mobile: form.phone,
					code: form.vaild
				}
				const results = await fetchPhoneLogin(params)

				store.commit('changeUserInfo', results)
				store.commit('chageLoginState', true)
				dialogVisible.value = false
			}
			return {
				store,
				dialogVisible,
				form,
				num,
				vaildFlag,
				handleVaildBtn,
				handleClickLogin
			}
		}
	}
</script>
<style lang="scss" scoped>
	.login_class {
		padding: 0 60px;
		.login_btn {
			display: flex;
			width: 50%;
			justify-content: center;
			margin: 0 auto;
		}
	}
</style>
