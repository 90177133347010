<!--
 * @Author: huangl
 * @Date: 2023-04-10 11:55:49
 * @LastEditors: Chenminggui111 874512298@qq.com
 * @LastEditTime: 2023-08-15 10:22:30
 * @Description: file content
 * @FilePath: \kingpuluse_pro\src\App.vue
-->
<template>
	<div id="app">
		<div
			class="nav-wrap"
			v-if="!isMobile"
		>
			<div class="nav">
				<div class="logo">
					<a href="/">
						<img src="./assets/image/logo.png" />
					</a>
				</div>

				<div class="list">
					<div
						v-show="store.state.ISLOGIN && store.state.USERINFO.authFlag === 1"
						v-on:mousemove="handleNavMove(item)"
						v-on:mouseout="currentNav = null"
						v-for="(item, index) in navList.data"
						:key="index"
						@click="handleTargetNav(item.id)"
					>
						{{ item.name }}
					</div>
				</div>

				<div
					class="category-wrap"
					v-show="currentNav == 1"
					v-on:mousemove="handleNavMove({ id: 1 })"
					v-on:mouseout="currentNav = null"
				>
					<div class="pro_cate">
						<div
							class="cate_item"
							v-for="(item, index) in allNavList.data"
							:key="index"
							@click="handleClickNav(item)"
						>
							{{ item.name }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			class="nav_moblie"
			v-else
		>
			<div class="logo_mobile">
				<a href="/">
					<img src="./assets/image/logo.png" />
				</a>
			</div>
			<div
				v-show="store.state.ISLOGIN && store.state.USERINFO.authFlag === 1"
				class="menu_mobile"
				@click="handleClickMenu"
			>
				<img src="./assets/image/menu.png" />
			</div>
		</div>
		<div class="router-views">
			<router-view />
		</div>

		<div
			class="footer"
			v-if="!isMobile"
		>
			<div class="top">
				<div class="link">
					<div style="margin-right: 69px">
						<div class="title">关注我们</div>
						<p>了解行业应用的最新咨询</p>
					</div>
					<div>
						<p>电话：0750-7777028</p>
						<p>邮件：K08@kingpulse.cn</p>
					</div>
				</div>

				<div class="footer_item">
					<div class="footer_img">
						<div style="margin-right: 20px">
							<img
								src="@/assets/image/connect1.png"
								class="img"
							/>
							<div class="fonter_connect_text">客户经理</div>
						</div>
						<div>
							<img
								src="@/assets/image/connect.png"
								class="img"
							/>
							<div class="fonter_connect_text">业务咨询</div>
						</div>
					</div>
				</div>
			</div>
			<p>粤ICP备2023127810号-1深圳市宇思技术有限公司</p>
		</div>

		<login ref="login" />
	</div>
</template>

<script>
	import { onMounted, reactive, ref, getCurrentInstance, toRaw } from 'vue'
	import { useRouter } from 'vue-router'
	import { useStore } from 'vuex'

	import { fetchGetProHome, fetchGetUserInfo } from '@/api/home'
	import login from '@/components/login/index.vue'

	export default {
		components: { login },
		setup() {
			let { proxy } = getCurrentInstance()
			// const dropdown = ref();
			const store = useStore()
			let isMobile = ref(false)
			let navList = reactive({
				data: [
					{
						id: '0',
						name: '首页'
					},
					{
						id: '1',
						name: '产品'
					},
					{
						id: '2',
						name: '下载'
					}
				]
			})
			let allNavList = reactive({ data: [] })
			let drawer = ref(false)

			// 当前的导航栏下标
			let currentNav = ref('0')

			onMounted(async () => {
				// const href = window.location.href
				// let str = href.substring(0, 5)
				// if (str !== 'https') {
				// 	let path = 'https://cp.jipulse.com/'
				// 	window.location.href = path
				// }
				isMobile.value = _isMobile()

				store.state.MOBILE = isMobile.value

				// 获取分类
				const results = await fetchGetProHome()

				allNavList.data = results
				const userInfo = toRaw(store.state.USERINFO)
				if (userInfo.mobile) {
					const data = await fetchGetUserInfo({ userName: userInfo.mobile })
					store.commit('changeUserInfo', data)
				}
			})

			if (store.state.CURRENT_NAV) {
				currentNav.value = store.state.CURRENT_NAV
			}
			const router = useRouter()
			const handleTargetNav = id => {
				if (id === '0') {
					router.push('/')
				}
				if (id === '2') {
					// 工具页面
					router.push('/tools')
				}
			}
			const handleClickNav = item => {
				router.push({
					path: '/pro',
					query: { id: item.id }
				})
			}

			// 鼠标移入
			const handleNavMove = item => {
				currentNav.value = item.id
			}

			// 判断当前所使用的的设备
			const _isMobile = () => {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				)
				return flag
			}

			const handleClickMenu = () => {
				router.push({
					path: '/classify'
				})
			}

			const openLogin = () => {
				proxy.$refs.login.dialogVisible = true
			}

			return {
				handleClickNav,
				navList,
				currentNav,
				handleTargetNav,
				store,
				isMobile,
				handleClickMenu,
				openLogin,
				drawer,
				allNavList,
				handleNavMove
			}
		}
	}
</script>

<style lang="scss" scoped>
	.router-views {
		min-height: calc(100vh - 280px);
	}
	html {
		font-size: 12px;

		@media screen and (min-width: 750px) {
			font-size: 16px;
		}
	}

	body {
		padding: 0;
		margin: 0;
	}
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		position: relative;
	}

	.img {
		width: 100px;
		height: 100px;
	}
	.nav_moblie {
		height: 4.68rem;
		padding: 0 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: #000;
		color: #fff;
	}
	.logo_mobile {
		width: 90px;
		height: 22px;
		img {
			width: 100%;
			height: 100%;
		}
	}
	.menu_mobile {
		width: 24px;
		height: 24px;
		img {
			width: 100%;
			height: 100%;
		}
	}
	.nav-wrap {
		width: calc(100% - 60px);
		padding: 0 30px;
		height: 63px;
		line-height: 63px;
		color: #fff;
		margin: 0 auto;
		background: #000;
		.nav {
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin: 0 auto;
			.category-wrap {
				z-index: 999;
				position: absolute;
				top: 63px;
				left: 0;
				right: 0;
				padding: 8px 0;
				background-color: rgba(0, 0, 0, 0.5);
				color: #fff;
				font-size: 16px;
				line-height: 32px;
				box-sizing: border-box;
				.pro_cate {
					width: 1200px;
					margin: 0 auto;
					display: flex;
					flex-wrap: wrap;
					.cate_item {
						padding: 12px;
						margin: 0 22px;
						cursor: pointer;
					}
					.cate_item:hover {
						opacity: 0.9;
					}
				}
			}
		}
	}

	.logo {
		margin-right: 30%;
		width: 193px;
		height: 49px;
	}
	.list {
		display: flex;
		position: relative;
		.block::after {
			content: '';
			position: absolute;
			display: block;
			bottom: 0;
			width: 0;
			height: 0;
			border-bottom: 10px solid #fff;
			border-right: 10px solid transparent;
			border-left: 10px solid transparent;
		}
		div {
			min-width: 70px;
			margin: 0 80px;
			text-align: center;
			color: #fff;
			display: flex;
			justify-content: center;
			font-size: 16px;
			cursor: pointer;
			overflow: hidden;
		}
		a {
			text-decoration: none;
		}
	}

	.footer {
		font-size: 14px;
		display: flex;
		flex-direction: column;
		align-items: center;
		background: rgba(0, 0, 0, 1);
		color: rgba(255, 255, 255, 1);

		.top {
			width: 100%;
			padding: 22px 15%;
			box-sizing: border-box;
			align-items: center;
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid #808080;
		}
		.footer_nav {
			font-size: 14px;
			font-weight: 700;
			margin-bottom: 12px;
		}

		.footer_img {
			display: flex;
			.fonter_connect_text {
				font-size: 14px;
				font-weight: bold;
				text-align: center;
			}
			img {
				width: 88px;
				height: 88px;
			}
		}
		.link {
			font-size: 14px;
			font-weight: 400;
			color: rgba(255, 255, 255, 1);
			display: flex;
			align-items: center;
			.title {
				font-size: 18px;
				font-weight: 700;
			}
		}
	}
	.el-drawer {
		width: 100%;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.5);
	}
	.list_item {
		color: #fff;
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 50px;
		border-bottom: 1px solid rgba(239, 239, 239, 1);
		cursor: pointer;
	}
	.icon {
		width: 6px;
		height: 10px;
		color: rgba(196, 196, 196, 1);
	}
	.selected {
		font-weight: bold;
		color: red;
	}
	.el-dropdown {
		-el-dropdown-menu-box-shadow: var(--el-box-shadow-light);
		--el-dropdown-menuItem-hover-fill: var(--el-color-primary-light-9);
		--el-dropdown-menuItem-hover-color: var(--el-color-primary);
		--el-dropdown-menu-index: 10;
		display: flex;
		position: relative;
		color: var(--el-text-color-regular);
		font-size: var(--el-font-size-base);
		align-items: center;
		vertical-align: top;
	}
	.example-showcase .el-dropdown-link {
		cursor: pointer;
		color: var(--el-color-primary);
		display: flex;
		align-items: center;
	}
	@media screen and (min-width: 1311px) {
		.nav {
			width: 1200px;
		}
	}
</style>
