<!--
 * @Author: huangl
 * @Date: 2023-04-22 17:32:24
 * @LastEditors: huangl
 * @LastEditTime: 2023-07-07 10:47:18
 * @Description: file content

 * @FilePath: \kingpuluse_pro\src\components\login\index.vue
-->

<template>
	<div class="form-box">
		<h4>请完善企业信息</h4>
		<el-form
			ref="ruleFormRef"
			:model="form"
			:rules="rules"
			class="demo-ruleForm"
			size="small"
			label-width="auto"
			status-icon
		>
			<el-form-item
				label="公司名称"
				prop="companyName"
			>
				<el-input
					v-model="form.companyName"
					placeholder="请输入你的公司名称"
				/>
			</el-form-item>
			<el-form-item
				label="地址"
				prop="address"
			>
				<el-input
					v-model="form.address"
					placeholder="请输入你的地址"
				/>
			</el-form-item>
			<el-form-item
				label="邮箱"
				prop="email"
			>
				<el-input
					v-model="form.email"
					placeholder="请输入你的邮箱"
				/>
			</el-form-item>
			<el-form-item
				label="职位"
				prop="position"
			>
				<el-input
					v-model="form.position"
					placeholder="请输入你的职位"
				/>
			</el-form-item>
			<el-form-item>
				<span>我想了解的产品</span>
				<span class="text1">*（勾选您所想要了解的产品）</span>
			</el-form-item>
			<el-form-item
				width="0"
				prop="interestedCategoryId"
			>
				<el-checkbox
					v-model="checkAll"
					:indeterminate="isIndeterminate"
					@change="handleCheckAllChange"
				>
					全部
				</el-checkbox>
				<el-checkbox-group
					v-model="form.interestedCategoryId"
					@change="handleCheckedCitiesChange"
				>
					<el-checkbox
						:value="item.id"
						:label="item.id"
						name="type"
						v-for="(item, index) in allNavList"
						:key="index"
					>
						{{ item.name }}
					</el-checkbox>
				</el-checkbox-group>
			</el-form-item>
		</el-form>
		<el-button
			round
			color="#000000"
			@click="submit(ruleFormRef)"
		>
			确认
		</el-button>
	</div>
</template>
<script>
	import { reactive, ref, onMounted } from 'vue'
	import { useStore } from 'vuex'
	import { saveCorporateInfo, allProductList, fetchGetUserInfo } from '@/api/home'

	export default {
		setup() {
			const store = useStore()

			let checkAll = ref(false)

			let ruleFormRef = ref(null)
			const isIndeterminate = ref(false)
			let allNavList = ref([])

			const form = reactive({
				address: '',
				companyName: '',
				email: '',
				interestedCategoryId: [],
				position: ''
			})
			const rules = reactive({
				address: [
					{
						required: true,
						message: '请填写',
						trigger: 'blur'
					}
				],
				companyName: [
					{
						required: true,
						message: '请填写',
						trigger: 'blur'
					}
				],
				email: [
					{
						required: true,
						message: '请填写',
						trigger: 'blur'
					}
				],
				position: [
					{
						required: true,
						message: '请填写',
						trigger: 'blur'
					}
				],
				interestedCategoryId: [
					{
						type: 'array',
						required: true,
						message: '请选择',
						trigger: 'change'
					}
				]
			})
			onMounted(async () => {
				// 获取分类
				const results = await allProductList()
				allNavList.value = results
			})

			const submit = async () => {
				ruleFormRef.value.validate(async (valid, fields) => {
					if (valid) {
						let { id, mobile } = JSON.parse(localStorage.getItem('userInfo'))
						let formData = {
							id,
							...form,
							interestedCategoryId: form.interestedCategoryId.join()
						}
						await saveCorporateInfo(formData)
						const data = await fetchGetUserInfo({ userName: mobile })
						store.commit('changeUserInfo', data)
					} else {
						console.log('error submit!', fields)
					}
				})
			}
			const handleCheckAllChange = val => {
				let ids = allNavList.value.map(item => item.id)
				form.interestedCategoryId = val ? ids : []
				isIndeterminate.value = false
			}
			const handleCheckedCitiesChange = value => {
				const checkedCount = value.length
				let productLength = allNavList.value.length
				checkAll.value = checkedCount === productLength
				isIndeterminate.value = checkedCount > 0 && checkedCount < productLength
			}

			return {
				store,
				form,
				submit,
				rules,
				checkAll,
				allNavList,
				ruleFormRef,
				isIndeterminate,
				handleCheckAllChange,
				handleCheckedCitiesChange
			}
		}
	}
</script>
<style lang="scss" scoped>
	::v-deep .el-form {
		.el-checkbox-group {
			display: flex;
			flex-wrap: wrap;
		}
		.el-input__wrapper {
			background-color: rgba(247, 247, 247, 1);
		}
	}
	.form-box {
		text-align: center;

		.el-button {
			width: 200px;
		}
		.text1 {
			font-size: 12px;
			color: rgba(166, 166, 166, 1);
		}
	}
</style>
